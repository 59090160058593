import { Link } from "@reach/router";
import { useForm } from "react-hook-form";

const AddPost = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    const resp = await fetch(
      "https://serverless-api.ricardonunosr6960.workers.dev/api/posts",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: data,
      }
    );
  };

  return (
    <>
      <p className="text-blue-600">
        <Link to="/">Go back</Link>
      </p>
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <label>Title</label>
        <input
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
          {...register("title", { required: true })}
        />
        <label>Content</label>
        <input
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
          {...register("content", { required: true })}
        />
        <label>Your user</label>
        <input
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
          {...register("username", { required: true })}
        />
        {errors.exampleRequired && <span>This field is required</span>}
        <input
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mt-4"
          type="submit"
        />
      </form>
    </>
  );
};

export default AddPost;
