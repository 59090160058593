import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://serverless-api.ricardonunosr6960.workers.dev/api/posts"
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-purple-600 text-6xl">Posts</h1>
        <Link
          to="/posts/add"
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 border border-blue-700 rounded "
        >
          Add a post
        </Link>
      </div>
      {posts.map((post, i) => (
        <div key={i} className="w-4/5 m-auto mb-2 mt-2">
          <h2>
            <Link to={`/posts/${i}`}>{post.title}</Link>
          </h2>
        </div>
      ))}
    </div>
  );
};

export default Posts;
