import { Router } from "@reach/router";

import Posts from "./components/posts";
import Post from "./components/post";
import AddPost from "./components/addPost";

function App() {
  return (
    <div className="w-1/2 h-screen m-auto bg-gray-100 p-6">
      <Router>
        <Posts path="/" />
        <Post path="/posts/:id" />
        <AddPost path="/posts/add" />
      </Router>
    </div>
  );
}

export default App;
