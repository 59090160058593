import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Post = ({ id }) => {
  const [post, setPost] = useState({});

  useEffect(() => {
    const getPost = async () => {
      const resp = await fetch(
        `https://serverless-api.ricardonunosr6960.workers.dev/api/posts/${id}`
      );
      const postResp = await resp.json();
      setPost(postResp);
    };

    getPost();
  }, [id]);

  if (!Object.keys(post).length) return <div />;

  return (
    <div >
      <h1 className="text-purple-600 text-2xl">{post.title}</h1>
      <p className="p-6">{post.content}</p>
      <p className="font-light text-gray-600">
        <em>Published by {post.username}</em>
      </p>
      <p className="text-blue-600">
        <Link to="/">Go back</Link>
      </p>
    </div>
  );
};

export default Post;